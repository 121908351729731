<template>
  <div class="goods-view">
    <div class="sctp-mar-b15 sctp-container">
      <div class="sctp-pad-tb10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>源码出售大厅</el-breadcrumb-item>
          <el-breadcrumb-item>源码详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <template v-if="notExist === true">
        <div style="padding: 50px 0;" class="sctp-bg-white">
          <empty :message="'源码不存在或已下架'"></empty>
        </div>
      </template>
      <template v-else>
        <div class="goods-header pad15 sctp-bg-white">
          <el-row type="flex" :gutter="10">
            <el-col class="flex flex-col" :span="7">
              <div class="sctp-bg-white flex-item1 ">
                <template
                  v-if="goodsInfo && goodsInfo.screenshots && goodsInfo.screenshots.length > 0">
                  <show-picture
                    :images="goodsInfo.screenshots"></show-picture>
                </template>
                <div class="pad-t10 flex flex-sb">
                  <div>
                    <template
                      v-if="!(goodsInfo && user && goodsInfo.userid === user.userId)"
                    >
                      <a v-if="goodsInfo && goodsInfo.favorite"
                         class="sctp-color-main"
                         @click="doPrivileged(favoriteProductConfig.onClick)">
                        <i class="el-icon-star-on"></i>
                        已收藏
                      </a>
                      <a v-else
                         @click="doPrivileged(favoriteProductConfig.onClick)">
                        <i class="el-icon-star-off"></i>
                        收藏商品
                      </a>
                    </template>
                  </div>
                  <a @click="reportConfig.showReport">
                    <i class="el-icon-warning"></i>
                    举报
                  </a>
                </div>
                <div class="view-control-wrap">
                  <div class="sctp-tc">
                    <div class="sctp-mar-b5">浏览数</div>
                    <div class="number">{{ goodsInfo && goodsInfo.hits || 0 }}</div>
                  </div>
                  <div class="sctp-tc">
                    <div class="sctp-mar-b5">沟通数</div>
                    <div class="number">{{ goodsInfo && goodsInfo.views || 0 }}</div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col class="flex flex-col" :span="12">
              <div class="sctp-bg-white flex-item1 pad-lr5">
                <div class="sctp-font-24 productName">{{
                    goodsInfo && goodsInfo.goodsname || ' '
                  }}
                </div>
                <div
                  class="sctp-mar-tb15 sctp-bg-f8 sctp-pad-tb15 sctp-pad-lr20 sctp-info sctp-font-14 ">
                  <div class="sctp-flex-item-1 sctp-flex" style="align-items: center;">
                    <div class="sctp-red sctp-font-28 sctp-mar-r15">
                      {{ goodsInfo && goodsInfo.saleprice && `¥${goodsInfo.saleprice}` || '免费' }}
                    </div>
                    <template v-if="goodsInfo && goodsInfo.realprice">
                      <div style="text-decoration: line-through;">原价：¥{{
                          goodsInfo.realprice
                        }}
                      </div>
                    </template>
                  </div>
                </div>
                <template v-if="goodsInfo">
                  <div class="sctp-flex sctp-pad-tb5">
                    <div>源码编号：</div>
                    <div>#{{ goodsInfo.goodsid }}</div>
                  </div>
                  <div class="sctp-flex sctp-pad-tb5">
                    <div>源码类型：</div>
                    <div>{{ codeType }}</div>
                  </div>
                  <div class="sctp-flex sctp-pad-tb5">
                    <div>版权属性：</div>
                    <template v-if="goodsInfo && goodsInfo.copyrightflag">
                      <div>有版权证</div>
                    </template>
                    <template v-else>
                      <div>无版权证</div>
                    </template>
                  </div>
                  <div class="sctp-flex sctp-pad-tb5">
                    <div>安装部署：</div>
                    <template v-if="goodsInfo && goodsInfo.install_flag">
                      <div>提供远程安装服务，
                      <template v-if="goodsInfo.installation_costs">
                        服务收费{{ goodsInfo.installation_costs.toMoney() }}元
                      </template>
                        <template v-else>
                          服务免费
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      <div>不提供远程安装服务</div>
                    </template>
                  </div>
                  <div class="sctp-flex sctp-pad-tb5">
                    <div>
                      演示网址：
                    </div>
                    <template v-if="goodsInfo.demourl">
                      <a @click="demoConfig.showDialog">查看</a>
                    </template>
                    <template v-else>无演示</template>
                    <el-dialog
                      width="500px"
                      center
                      :visible.sync="demoConfig.show"
                      title="查看演示"
                    >
                      <div style="line-height: 2">
                        <div>
                          演示账号：{{ goodsInfo.demoaccount || '无' }}
                        </div>
                        <div>
                          演示密码：{{ goodsInfo.demopassword || '无' }}
                        </div>
                        <div>演示地址：
                          <template v-if="goodsInfo.demoflag === 1">
                            <a style="color: #3195D2;" target="_blank"
                               :href="goodsInfo.demourl">{{
                                '查看'
                              }}</a>
                          </template>
                          <template v-else-if="goodsInfo.demoflag === 2">
                            <el-image
                              style="height: 80px;width: 80px;"
                              :src="goodsInfo.demourl"
                            ></el-image>
                          </template>
                        </div>
                      </div>
                    </el-dialog>

                  </div>
                </template>
                <div class="sctp-pad-tb10 sctp-info">
                  服务由"{{ shopInfo && shopInfo.shopname || ' ' }}"发货，并提供售后服务。
                </div>
                <div>
                  <el-input-number
                    :disabled="!hasStock"
                    :step-strictly="true" v-model="buyCount" :min="1"
                    :max=" goodsInfo && goodsInfo.stock || Infinity "
                  ></el-input-number>
                  <template v-if="!goodsInfo ||  !goodsInfo.isExist">
                    <el-button
                      :disabled="!hasStock"
                      @click="doPrivileged(addCart)" type="primary"
                    >
                      加入购物车
                    </el-button>
                  </template>
                  <template v-else>
                    <el-button @click="goPage('/shopping-cart')" type="primary">已在购物车</el-button>
                  </template>
                  <el-button
                    :disabled="!hasStock"
                    @click="doPrivileged(buyNow)" type="success"
                  >
                    立即购买
                  </el-button>
                  <span class="sctp-red" v-if="!hasStock">库存不足</span>
                </div>
                <div class="sctp-mar-t10 sctp-pad-b15"
                     style="border-top: 1px #e5e5e5 dashed;"></div>
                <div>
                  <el-tabs value="second" type="card">
                    <el-tab-pane label="发货模式" name="second">
                      <div style="line-height: 2;">商家支持的发货模式：</div>
                      <div style="line-height: 2;" v-for="item in deliveryMode">{{ item }}</div>
                    </el-tab-pane>
                    <el-tab-pane label="交易形式" name="third">
                      <div>卖家期望的交易模式：{{ transactionForm }}</div>
                      <div class="fz-12">(提醒：私下交易可能存在风险)</div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="sctp-pad15 sctp-bg-white" style="border: 1px solid #eee;">
                <div class="sctp-flex sctp-pad-b15 hairline-bottom">
                  <el-avatar class="sctp-mar-r10"
                             :src="shopInfo && shopInfo.logo"></el-avatar>
                  <div class="sctp-flex-item-1 sctp-flex sctp-flex-sb sctp-flex-column">
                    <div>{{ shopInfo && shopInfo.shopname }}的小店</div>
                    <div class="sctp-flex sctp-font-12">
                      <div class="sctp-mar-r5"><span><img class="auth_ok_icon"
                                                          src="/images/icon/shop_ok.png"/></span><span>店铺认证</span>
                      </div>
                      <div><span><img class="auth_ok_icon"
                                      src="/images/icon/user_ok.png"/></span><span>实名认证</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sctp-info sctp-font-14 sctp-pad-tb10 shop-info">
                  <div class="sctp-flex">
                    <div class="title">店家：</div>
                    <div class="value">{{ shopInfo && shopInfo.shopname }}</div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">宝贝：</div>
                    <div class="value">{{ shopInfo && shopInfo.goodsCount }}件</div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">地址：</div>
                    <div class="value">{{ shopAddress }}</div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">创店：</div>
                    <div class="value">{{
                        shopInfo && shopInfo.createtime | dateString('yyyy-MM-dd')
                      }}
                    </div>
                  </div>
                  <div class="sctp-flex">
                    <div class="title">联系：</div>
                    <div class="value"><a class="sctp-blue"
                                          @click="doPrivileged(viewPhoneDialogConfig.onViewClick)">查看联系方式</a>
                    </div>
                  </div>
                </div>
                <div>
                  <router-link :to="'/service-provider/view/' + (shopInfo && shopInfo.shopid || '')">
                    <el-button icon="el-icon-s-shop" size="mini">进入店铺</el-button>
                  </router-link>
                  <template
                    v-if="!(shopInfo && user && shopInfo.userid === user.userId)"
                  >
                    <el-button v-if="shopInfo && shopInfo.favorite"
                               @click="doPrivileged(favoriteShop)"
                               size="mini" icon="el-icon-star-on" type="primary"
                               :loading="!collectionBtnCanClickFlag"
                               class="mg-l5"
                    >
                      已收藏
                    </el-button>
                    <el-button v-else
                               :loading="!collectionBtnCanClickFlag"
                               @click="doPrivileged(favoriteShop)"
                               size="mini" icon="el-icon-star-off"
                               class="mg-l5"
                    >收藏店铺
                    </el-button>
                  </template>
                </div>
                <div class="mg-t10" v-if="shopInfo && shopInfo.deposit">
                  <el-alert
                    class="deposit-alter"
                    center
                    type="success"
                    :closable="false">
                    <div slot="title">
                      商家已缴纳保证金<span class="sctp-color-main fz-16">{{
                        shopInfo.deposit.toMoney()
                      }}</span>元
                    </div>
                  </el-alert>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="goods-body sctp-mar-t15">
          <el-row :gutter="15">
            <el-col :span="19">
              <el-tabs value="first" class="body-tabs" type="border-card">
                <el-tab-pane label="源码详情" name="first">
                  <div class="">
                    <div class="block-info">
                      <el-row :gutter="0">
                        <el-col :span="8">
                          <div class="block-info-item hairline-round">
                            <div>源码类型：</div>
                            <div>{{ codeType }}</div>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="block-info-item hairline-round">
                            <div>开发语言：</div>
                            <div>{{ language }}</div>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="block-info-item hairline-round">
                            <div>数据库：</div>
                            <div>{{ database }}</div>
                          </div>
                        </el-col>
                        <!--<el-col :span="8">-->
                        <!--  <div class="block-info-item hairline-round">-->
                        <!--    <div>支持终端：</div>-->
                        <!--    <div>{{ supportTerminal }}</div>-->
                        <!--  </div>-->
                        <!--</el-col>-->
                        <el-col :span="8">
                          <div class="block-info-item hairline-round">
                            <div>类别：</div>
                            <div>{{ type }}</div>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="block-info-item hairline-round">
                            <div>大小：</div>
                            <div>{{ goodsInfo && goodsInfo.codesize + 'KB' || ' ' }}</div>
                          </div>
                        </el-col>
                        <el-col :span="24">
                          <div class="block-info-item hairline-round">
                            <div>开发框架：</div>
                            <div>{{ goodsInfo && goodsInfo.framework || ' ' }}</div>
                          </div>
                        </el-col>
                        <el-col :span="24">
                          <div class="block-info-item hairline-round">
                            <div>部署环境：</div>
                            <div>{{ goodsInfo && goodsInfo.deployenv || ' ' }}</div>
                          </div>
                        </el-col>
                      </el-row>
                    </div>
                    <div style="padding: 50px 0 20px;">
                      <el-divider><span class="sctp-font-24">源码详情</span></el-divider>
                    </div>
                    <div class="sctp-pad15">
                      <div class="rich-text" v-html="goodsInfo && goodsInfo.introduce || ' '"></div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="代码片段" name="second">
                  <div class="">
                    <template v-if="sourceCode && sourceCode.length > 0">
                      <el-tabs type="card" :value="sourceCode[0].id + ''">
                        <template v-for="item in sourceCode">
                          <el-tab-pane :key="item.id" :label="item.title" :name="item.id + ''">
                            <div class="sctp-pad15">
                              <div class="rich-text" v-html="item.content"></div>
                            </div>
                          </el-tab-pane>
                        </template>
                      </el-tabs>
                    </template>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="版权信息" name="five">
                  <template v-if="goodsInfo && goodsInfo.copyrightflag === 1">
                    <div>
                      <div>
                        {{
                          goodsInfo.transfer_copyright ? '' : '不'
                        }}转让版权，
                        有版权证,价格{{ goodsInfo.contain_copyright_price ? '' : '不' }}包含版权
                        <div v-if="goodsInfo.copyrightFee || goodsInfo.copyrightFee === 0">版权价格：{{
                            goodsInfo.copyrightFee.toMoney()
                          }}元
                        </div>
                      </div>
                      <div class="sctp-tc">
                        <el-image
                          style="height: 400px;width: 282px;"
                          :src="goodsInfo.copyright_imgurl"
                          fit="contain">
                        </el-image>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="">无版权证</div>
                  </template>
                </el-tab-pane>
                <el-tab-pane label="商品评价" name="third">
                  <div class="">
                    <comment type="SOURCECODE" :id="Number(id)"></comment>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="交易流程" name="fourth">
                  <div class="">
                    <div class="rich-text" v-html="transactionProcessConfig.content"></div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-col>
            <el-col :span="5">
              <template
                v-if="recommendProductConfig.data && recommendProductConfig.data.length > 0">
                <div class="sctp-bg-white">
                  <div class="sctp-flex sctp-flex-sb sctp-pad-tb15">
                    <div class="slider-card-title">推荐源码</div>
                    <div></div>
                  </div>
                  <template v-for="(recommendItem,index) in recommendProductConfig.data">
                    <a target="_blank" :href="'/sourcecode/view/' + recommendItem.productId"
                       :key="recommendItem.productId">
                      <div class="sctp-flex sctp-wfull sctp-pad5">
                        <el-image
                          class="sctp-mar-r10"
                          :src="recommendItem.productLogo"
                          fit="cover"
                          style="width:100px;height:100px;"
                        ></el-image>
                        <div class="sctp-flex-item-1">
                          <div class="flex flex-sb flex-col full">
                            <div>
                              <div class="sctp-ellipsis--l3">{{ recommendItem.productName }}</div>
                              <div class="fz-12 fc-info">{{ recommendItem.codeTypeName }}</div>
                            </div>
                            <div class="sctp-mar-t5"><span>价格：</span><span class="sctp-red">¥{{
                                recommendItem.productPrice
                              }}</span></div>
                          </div>
                        </div>
                      </div>
                      <template v-if="index !== recommendProductConfig.data.length - 1">
                        <div class="divider"></div>
                      </template>
                    </a>
                  </template>
                </div>
              </template>
            </el-col>
          </el-row>
        </div>
        <el-dialog
          center
          title="查看联系方式"
          :visible.sync="viewPhoneDialogConfig.showPayDialog"
          width="600px"
        >
          <pay :pay-amount="shopInfo && shopInfo.viewContactPrice || 0"
               @pay="viewPhoneDialogConfig.onPay"
               :can-use-reward="true"
          ></pay>
        </el-dialog>
        <el-dialog
          center
          title="联系方式"
          width="500px"
          :visible.sync="viewPhoneDialogConfig.show"
        >
          <div>
            <div class="flex pad-tb5">
              <div class="sctp-tr" style="width: 100px;">手机号码：</div>
              <div>{{ shopInfo && shopInfo.phone || '未设置' }}</div>
            </div>
            <div class="flex pad-tb5">
              <div class="sctp-tr" style="width: 100px;">Email邮箱：</div>
              <div>{{ shopInfo && shopInfo.email || '未设置' }}</div>
            </div>
            <div class="flex pad-tb5">
              <div class="sctp-tr" style="width: 100px;">QQ号：</div>
              <div>{{ shopInfo && shopInfo.qq || '未设置' }}</div>
            </div>
            <div class="flex pad-tb5">
              <div class="sctp-tr" style="width: 100px;">微信号：</div>
              <div>{{ shopInfo && shopInfo.wechat || '未设置' }}</div>
            </div>
            <div class="flex pad-tb5">
              <div class="sctp-tr" style="width: 100px;">微信二维码：</div>
              <div>
                <template v-if="shopInfo && shopInfo.wechat_qrcode">
                  <el-image
                    :src="shopInfo.wechat_qrcode"
                    style="width: 80px;height: 80px;"
                    fit="contain"
                  ></el-image>
                </template>
                <template v-else>
                  未设置
                </template>
              </div>
            </div>
            <div class="fz-12 fc-info">
              注：以上由卖家自行填写，请及时联系，联系时请说明信息来源。
            </div>
          </div>
        </el-dialog>
        <el-dialog
          center
          title="源码信息内容举报"
          :visible.sync="reportConfig.show"
        >
          <report
            class="report"
            @success="reportConfig.hiddenReport"
            :target="{
             title:goodsInfo && goodsInfo.goodsname,
             id: goodsInfo && goodsInfo.goodsid
          }"
            :type="3"></report>
        </el-dialog>
        <el-dialog
          title="平台服务条款"
          :visible.sync="centerDialogVisible"
          class="register-dialog"
          center>
          <div class="rich-text" v-html="productReleaseTerms"></div>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog
          title="查看联系方式需支付服务费"
          :visible.sync="openPayAfterFlag"
          width="26%"
          class="register-dialog"
          center>
          <div>
            <div style="text-align: center;color: red;font-size: 20px">{{shopInfo.viewContactPrice.toMoney()}}元</div>
            <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
            <div class="flex flex-center">
              <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasPhone"></i>
                <i class="el-icon-error" v-else></i>手机号</div>
              <div class="sctp-mar-r10"><i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasEmail"></i>
                <i class="el-icon-error" v-else></i>邮箱</div>
              <div class="sctp-mar-r10">
                <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasQQ"></i>
                <i class="el-icon-error" v-else></i>QQ</div>
              <div class="sctp-mar-r10">
                <i class="el-icon-success sctp-color-main" v-if="shopInfo && shopInfo.hasWechat"></i>
                <i class="el-icon-error" v-else></i>微信</div>
            </div>
          </div>
          <div class="sctp-flex sctp-flex-center" style="padding-top: 10px">
            <el-checkbox v-model="read">
              我已阅读
              <a @click.prevent="centerDialogVisible = true" class="sctp-color-main">《平台服务条款》</a>
              并同意
            </el-checkbox>
          </div>
          <div slot="footer" class="dialog-footer">

            <el-button @click="openPayAfterFlag = false" >取消</el-button>
            <el-button type="primary" @click="openPay()">支付</el-button>
          </div>
        </el-dialog>
      </template>
    </div>
  </div>
</template>

<script>
import {cart, collection, common, goods, shop} from "../../../../apis";
import area from "@/resources/js/area";

const config = require('../../../../resources/js/config');
export default {
  name: 'productView',
  components: {
    pay: () => import('@CMP/common/BuyPay'),
    report: () => import('@CMP/common/UserReport'),
    empty: () => import('@CMP/common/EmptyData.vue'),
    comment: () => import('@PAGE/shop-manage/production-manage/production-commnet/CommentContent'),
    ShowPicture: () => import('@CMP/product/ShowPicture'),
  },
  data() {
    return {
      notExist: null,
      collectionBtnCanClickFlag: true,
      buyCount: 1,
      shopInfo: null,
      goodsInfo: null,
      options: null,
      openPayAfterFlag:false,
      productReleaseTerms:null,
      read:false,
      centerDialogVisible:false,
      sourceCode: [],
      // 所有评价
      comments: [],
      viewPhoneDialogConfig: {
        show: false,
        showPayDialog: false,
        onViewClick: () => {
          if (!this.shopInfo) {
            return;
          }
          if (this.shopInfo.showContact === true) {
            this.viewPhoneDialogConfig.show = true;
          } else {
            common.contactClickHistory({
              userId: this.user.userId,
              id: this.id,
              type: 3,
            })
            this.viewPhoneDialogConfig.confirmPay();
          }
        },
        confirmPay: () => {
          let {shopInfo} = this;
          if (shopInfo.viewContactPrice == 0) {
            this.viewPhoneDialogConfig.show = true;
            return;
          }
          if (!shopInfo ) {
            return;
          }
          this.openPayAfterFlag = true
          // this.$confirm(`<div>
          //     <div class="sctp-red sctp-font-18">${shopInfo.viewContactPrice.toMoney()}元</div>
          //     <div style="text-align: left;">确认支付代表知晓并同意本次服务费及平台的免责条款，以下联系方式仅打勾的有效</div>
          //     <div class="flex flex-center">
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasPhone
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}手机号</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasEmail
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}邮箱</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasQQ
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}QQ</div>
          //     <div class="sctp-mar-r10">${shopInfo && shopInfo.hasWechat
          //   ? '<i class="el-icon-success sctp-color-main"></i>' : '<i class="el-icon-error"></i>'}微信</div>
          //     </div>
          //     </div>`, {
          //   dangerouslyUseHTMLString: true,
          //   confirmButtonText: '支付',
          //   cancelButtonText: '取消',
          //   center: true,
          //   title: '查看联系方式需支付服务费',
          // }).then(() => {
          //   this.viewPhoneDialogConfig.showPayDialog = true;
          // })
        },
        onPay: ({payWay}) => {
          shop.viewContactInformation({
            userId: this.user.userId,
            shopId: this.shopInfo.shopid,
            payWay: payWay,
            eType: 2,
            eId: this.id
          }).then(() => {
            this.viewPhoneDialogConfig.showPayDialog = false;
            this.getGoodsDetail().then(() => {
              this.viewPhoneDialogConfig.show = true;
            });
          })
        },
      },
      reportConfig: {
        show: false,
        showReport: () => {
          this.reportConfig.show = true
        },
        hiddenReport: () => {
          this.reportConfig.show = false
        }
      },
      favoriteProductConfig: {
        onClick: () => {
          if (!this.favoriteProductConfig.canClick) {
            return;
          }
          this.favoriteProductConfig.canClick = false;
          collection.addFavorite({
            userId: (this.user && this.user.userId) || null,
            targetId: this.id,
            targetType: 2
          }).then(res => {
            const {retdata} = res;
            this.goodsInfo.favorite = retdata;
            if (retdata) {
              this.$message.success('收藏成功')
            } else {
              this.$message.success('取消收藏成功')
            }
          }).finally(() => {
            this.favoriteProductConfig.canClick = true;
          })
        },
        canClick: true,
      },
      recommendProductConfig: {
        data: [],
        page: 1,
        limit: 5,
        loadData: () => {
          goods.selectRecommendSourceCode({
            productId: this.id,
            page: this.recommendProductConfig.page,
            limit: this.recommendProductConfig.limit,
            productType: 'SOURCECODE',
          }).then(res => {
            let {retdata, count} = res;
            this.recommendProductConfig.data = retdata;
          })
        },
      },
      transactionProcessConfig: {
        content: '',
        loadData: () => {
          common.loadProtocol({
            protocolName: '服务交易流程'
          }).then(res => {
            let {retdata} = res;
            this.transactionProcessConfig.content = retdata;
          })
        }
      },
      demoConfig: {
        show: false,
        showDialog: () => {
          this.demoConfig.show = true;
        }
      }
    }
  },
  props: ['id'],
  methods: {
    getGoodsDetail() {
      if (!this.id) {
        return Promise.reject();
      }
      return this.$request.post({
        reqcode: '1015',
        reqdesc: '源码详情',
        goodsId: this.id,
        userId: this.user && this.user.userId
      }).then(res => {
        const {retdata, shopInfo, sourceCode = []} = res;
        // 保存浏览历史记录
        common.viewHistory({
          userId: (this.user && this.user.userId) || null,
          type: 2,
          id: this.id,
          title: retdata.goodsname
        });
        if (retdata) {
          retdata.screenshot = retdata.screenshot && retdata.screenshot.split(',');
          retdata.createtime = retdata.createtime && this.dateToString(new Date(retdata.createtime),
            'yyyy-MM-dd HH:mm:ss');
          retdata.createtime = retdata.updatetime && this.dateToString(new Date(retdata.updatetime),
            'yyyy-MM-dd HH:mm:ss');
          this.goodsInfo = retdata
        }
        this.sourceCode = sourceCode.map(item => {
          return {
            id: item.id,
            content: item.content,
            title: item.title
          }
        })
        if (shopInfo) {
          this.shopInfo = shopInfo
        }


      }).catch(err => {
        if (err.retCode === '9000') {
          this.notExist = true;
        }
        return Promise.reject(err);
      });
    },
    getOptions() {
      return this.$request.post({
        reqcode: '1011',
        reqdesc: '添加商品选项'
      }).then(res => {
        this.options = res.retdata
      })
    },
    addCart() {
      if (this.goodsInfo.status ==0){
        this.$message.error("商品已下架或已经删除")
        return
      }
      if (!this.goodsInfo) {
        return Promise.reject();
      }
      return goods.addCart({
        userId: this.user.userId,
        goodsId: this.goodsInfo.goodsid,
        amount: this.buyCount,
        type: 2,
      }).then(res => {
        this.$set(this.goodsInfo, 'isExist', true);
        this.$Bus.$emit('cartCountChange');
      })
    },
    buyNow() {
      cart.buyNow({
        id: this.id,
        type: 'SOURCECODE',
        amount: this.buyCount,
      });
    },
    /**
     * 收藏店铺
     */
    favoriteShop() {
      if (!this.shopInfo) {
        return;
      }
      this.collectionBtnCanClickFlag = false;
      collection.addFavorite({
        userId: (this.user && this.user.userId) || null,
        targetId: this.shopInfo.shopid,
        targetType: 1,
      }).then(res => {
        const {retdata} = res;
        this.shopInfo.favorite = retdata;
        if (retdata) {
          this.$message.success('收藏成功')
        } else {
          this.$message.success('取消收藏成功')
        }
      }).finally(() => {
        this.collectionBtnCanClickFlag = true
      })
    },
    /**
     * 获取商品评价
     */
    getComment() {
      this.$request.post({
        reqcode: '1085',
        reqdesc: '商品评价',
        productId: this.id
      }).then(res => {
        const {retdata} = res;
        retdata.forEach(item => {
          if (item.pictures) {
            item.pictures = item.pictures.split(',').map(item =>  item)
          }
          if (item.avatar) {
            item.avatar =  item.avatar
          }
          // 回复标记
          item.replyFlag = false;
          item.canClick = true
        });
        this.comments = retdata
      })
    },
    /**
     * 商家回复评论
     */
    onReplyClick(comment) {
      comment.canClick = false;
      let {replyContent} = comment;
      replyContent = replyContent.trim();
      if (!replyContent) {
        comment.canClick = true;
        return false
      }
      this.doCommitReply(comment.commid, comment.replyContent)
      .then((res) => {
        const {retdata} = res;
        const reply = comment.reply || [];
        reply.push(retdata);
        comment.reply = reply;
        comment.replyContent = '';
        comment.canClick = true;
        comment.replyFlag = false
      })
      .catch(() => {
        comment.canClick = true
      })
    },
    doCommitReply(id, content) {
      return this.$request.post({
        reqcode: '1092',
        reqdesc: '商家回复',
        commid: id,
        replycontent: content
      })
    },
    getProductReleaseTerms() {
      common.loadProtocol({
        protocolName: '平台服务条款'
      }).then(res => {
        const {retdata} = res;
        this.productReleaseTerms = retdata
      })
    },
    openPay(){
      if (!this.read){
        this.$message.warning('请确认阅读条款');
        return
      }
      this.openPayAfterFlag =false
      this.viewPhoneDialogConfig.showPayDialog =true
    }
  },
  computed: {
    shopAddress() {
      let ret = '';
      if (this.shopInfo) {
        let province = area.parseProvince(this.shopInfo.provinceCode);
        let city = area.parseCity(this.shopInfo.cityCode);
        ret = `${province.name}-${city.name}`;
      }
      return ret;
    },
    hasStock() {
      const {goodsInfo} = this;
      let flag = true;
      if (goodsInfo) {
        if (goodsInfo.stock !== undefined && goodsInfo.stock !== null) {
          flag = goodsInfo.stock > 0
        }
      }
      return flag
    },
    language() {
      return this.goodsInfo && this.options && ((this.options.language || []).filter(
        item => item.lngid === this.goodsInfo.lngid)[0] || {}).lngname || ''
    },
    database() {
      return this.goodsInfo && this.options && ((this.options.database || []).filter(
        item => item.dbtypeid === this.goodsInfo.dbtypeid)[0] || {}).dbname || ''
    },
    type() {
      let value = '';
      if (this.goodsInfo && this.options) {
        if (this.options.type) {
          this.options.type.forEach(first => {
            if (first.typeId === this.goodsInfo.typeid) {
              value += (first.typeName + ' > ');
              if (first.children.length > 0) {
                first.children.forEach(second => {
                  if (second.typeId === this.goodsInfo.subtypeid) {
                    value += second.typeName;
                    return false
                  }
                })
              }
            }
          })
        }
      }
      return value
    },
    codeType() {
      return this.goodsInfo && this.options && ((this.options.codeType || []).filter(
        item => item.reqtypeid === this.goodsInfo.codetypeid)[0] || {}).typename || ''
    },
    deliveryMode() {
      let {goodsInfo} = this;
      const form = {
        1: '邮件发送(手动)',
        2:  `网盘下载(自动) ${goodsInfo && goodsInfo.netdiscname || ''}`,
        3: '网站直接下载(自动)',
        4: '物流邮寄(手动)',
      };
      return (goodsInfo && goodsInfo.saletype && goodsInfo.saletype.split(',')
      .map(item => form[item])) || []
    },
    transactionForm() {
      const mode = {
        1: '私下交易(非担保交易)',
        2: '担保交易',
        3: '私下交易(非担保交易) 或 担保交易',
      };
      return (this.goodsInfo && mode[this.goodsInfo.dealmodel]) || ''
    }
  },
  beforeMount() {
    this.getGoodsDetail();
    this.recommendProductConfig.loadData();
    this.getOptions();
    this.transactionProcessConfig.loadData();
    this.getProductReleaseTerms()
  }
}
</script>

<style scoped lang="scss">
.goods-view ::v-deep .el-dialog__body {

  overflow-y: auto;
}

.goods-header .el-carousel {
  width: 100%;
}

.goods-header .el-input-number {
  width: 150px;
  margin-right: 10px;
}

.goods-header .el-avatar {
  height: 50px;
  width: 50px;
}

.goods-header .shop-info div {
  line-height: 28px;
}

.goods-header .shop-info .title {
  display: inline-block;
  margin-right: 5px;
}

.goods-header .shop-info .value {
  flex: 1;
}

.block-info .block-info-item {
  min-height: 35px;
  display: flex;
}

.view-control-wrap {
  margin-top: 15px;
  display: flex;
  //border-style: solid;
  //border-width: 1px 0 1px 0;
  //border-color: #eee;

  & > div {
    flex: 1;
    padding: 8px 0;
    border-width: 1px;
    border-style: solid;
    border-color: #eee;

    .number {
      font-size: 20px;
      font-weight: bold;
    }
  }

  & > div:last-child {
    border-left: unset;

    .number {
      color: #F90;
    }
  }
}

.block-info .block-info-item div:first-child {
  background-color: #f6f6f6;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-right: 1px solid #e5e5e5;
}

.block-info .block-info-item div:nth-child(2) {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  white-space: pre-wrap;
}

.goods-score div {
  position: relative;
}

.goods-score div:not(:last-child):after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  background-color: red;
  width: 1px;
}

.goods-score div span:nth-child(1) {
  color: #F90;
  font-weight: bold;
  margin-bottom: 10px;
}

.el-divider--horizontal {
  width: 50%;
  transform: translateX(50%)
}

.tips-info {
  padding: 15px;
  line-height: 1.2;
}

.auth_ok_icon {
  height: 12px;
  width: 12px;
  object-fit: cover;
  vertical-align: middle;
  margin-right: 3px;
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 5px;
  width: 100%;
}
.report{
  overflow: auto;
  &::-webkit-scrollbar{
    width: 3px;
  }
}
</style>
